<template>
  <div class="addons-pricing">
    <h2 class="question-title">
      How often would you like to have the add-ons mailed to you?
    </h2>
    <div v-for="(item, i) in body" :key="item.id">
      <b-card no-body style="background-color: rgba(242, 242, 242, 0.5)">
        <b-row no-gutters align-v="stretch" style="min-height: 100%">
          <b-col
            :cols="item.image ? '8' : '12'"
            class="pl-4 "
            align-self="center"
          >
            <h3 class="mb-4">Your add-ons:</h3>
            <h2 class="mb-0">
              <b>{{ item.name }}</b>
            </h2>
          </b-col>
          <b-col cols="4" class="p-0" v-if="item.image">
            <div class="img-holder">
              <img :src="item.image" :alt="item.name" />
            </div>
          </b-col>
        </b-row>
      </b-card>
      <!-- NOTE: The description is a temporary measure. -->
      <!-- for description just add props 'description' -->
      <w-checkbox
        type="radio"
        v-for="pricing in item.pricing"
        :name="pricing.product"
        :key="pricing.id"
        :id="pricing.id"
        :label="pricing.nickname"
        :pricing="formatPricing(pricing.type, pricing.price, pricing.recurring)"
        :value="{
          productid: item.id,
          pricingid: pricing.id,
        }"
        bold
        v-model="items[i]"
      >
      </w-checkbox>
    </div>
    <b-button
      pill
      block
      variant="info"
      :disabled="disabled"
      @click="submitPricing"
      >Continue</b-button
    >
  </div>
</template>

<script>
export default {
  props: {
    body: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    submitPricing() {
      this.items = this.items.filter((item) => {
        return item != null;
      });
      this.$emit("submitPricing", this.items);
    },
    formatPricing(type, price, recurring) {
      if (type == "one_time") {
        return `One time payment of $${price}`;
      } else if (
        recurring.interval == "day" &&
        recurring.interval_count % 30 == 0
      ) {
        let isMoreThanOneMonth = recurring.interval_count / 30 > 1;
        let plural = isMoreThanOneMonth ? "s" : "";
        let interval = isMoreThanOneMonth
          ? recurring.interval_count / 30 + " "
          : "";
        return `$${price}/${interval}month${plural}`;
      } else {
        return `$${price}/${recurring.interval_count}${recurring.interval}(s)`;
      }
    },
  },
};
</script>

<style scoped>
/deep/.c-radio {
  border-radius: 10px !important;
}

/deep/.c-radio input + label:after {
  border-radius: 10px !important;
}
</style>

<style lang="scss" scoped>
.img-holder {
  width: 100% !important;
  height: 140px !important;
  text-align: center;
  margin-right: 0 !important;

  img {
    height: 100%;
    object-fit: contain;
  }
}
</style>
