<template>
  <div class="addons" v-if="addonsReady">
    <div v-if="nonrx" class="mt-4 mb-5">
      <h2 class="question-title with-subtext">
        Our non-Rx favorites
      </h2>
      <p class="sub-text">
        These are some of our favorite non-Rx products, we have made everything
        that is just right for you. Select all products that you like!
      </p>
    </div>

    <h2 class="mt-4 question-title" v-else>
      Would you like to add any of these additional items to your cart?
    </h2>
    <w-checkbox
      type="checkbox"
      v-model="answers"
      v-for="item in body.data"
      :key="item.id"
      :value="item"
      :id="item.id"
      :label="item.name"
      :description="item.description"
      :pricing="getPricing(item.pricing)"
      :clamp-text="2"
      :recommended="recommendedItems.includes(item.id)"
      class="addons"
      bold
      align-v="start"
    >
      <!-- <h3 class="mt-1"><b>{{ item.name }}</b></h3>
      <span >{{ item.description }}</span> -->
      <template v-if="item.image" v-slot:image>
        <div class="img-holder">
          <img
            :src="
              item.image == null
                ? require('@/assets/images/dashboard/articlebg.png')
                : item.image
            "
            :alt="item.name"
          />
        </div>
      </template>
    </w-checkbox>
    <b-button
      variant="info"
      pill
      block
      @click="submitAddons"
      :disabled="disabled"
      >Next</b-button
    >
  </div>
</template>

<script>
import { OnboardingService } from "@/services/onboarding.service";

export default {
  props: {
    body: {
      type: Object,
    },
    nonrx: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    chosenAddons: {
      type: Array,
    },
    productRevealData: {
      type: Array,
    },
  },
  data() {
    return {
      answers: [],
      recommendedItems: [],
      addonsReady: false,
    };
  },
  methods: {
    submitAddons() {
      this.$emit("submitAddons", this.answers);
    },
    getPricing(pricings) {
      // * Gets the lowest price from the array
      let priceArray = [];
      let lowestPricing = {};
      for (let pricing of pricings) {
        priceArray.push(pricing.price);
        if (pricing.price == Math.min(priceArray)) {
          lowestPricing = pricing;
        }
      }
      return this.formatPricing(
        lowestPricing.type,
        lowestPricing.price,
        lowestPricing.recurring
      );
    },
    formatPricing(type, price, recurring) {
      if (type == "one_time") {
        return `One time payment of $${price}`;
      } else if (
        recurring.interval == "day" &&
        recurring.interval_count % 30 == 0
      ) {
        let isMoreThanOneMonth = recurring.interval_count / 30 > 1;
        let plural = isMoreThanOneMonth ? "s" : "";
        let interval = isMoreThanOneMonth
          ? recurring.interval_count / 30 + " "
          : "";
        return `$${price}/${interval}month${plural}`;
      } else {
        return `$${price}/${recurring.interval_count}${recurring.interval}(s)`;
      }
    },
    getRecommendedAddons() {
      const data = this.productRevealData;
      for (let item of this.body.data) {
        let foundItem = data.filter((addon) => {
          if (addon.id == item.id) {
            return addon;
          }
        });

        if (foundItem.length == 1) {
          if (this.chosenAddons.length == 0) {
            this.answers.push(item);
          }
          this.recommendedItems.push(item.id);
        }
      }
    },
    getAnswers() {
      for (let item of this.chosenAddons) {
        let searchAddons = this.body.data.filter((addon) => {
          if (addon.id == item.productid) {
            this.answers.push(addon);
          }
        });
      }
    },
  },
  mounted() {
    if (!this.nonrx) this.getRecommendedAddons();
    if (this.chosenAddons.length > 0) this.getAnswers();
    this.addonsReady = true;
  },
};
</script>

<style scoped>
.img-holder {
  display: flex;
  align-self: center;
}
</style>
