var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "addons-pricing" },
    [
      _c("h2", { staticClass: "question-title" }, [
        _vm._v(" How often would you like to have the add-ons mailed to you? "),
      ]),
      _vm._l(_vm.body, function (item, i) {
        return _c(
          "div",
          { key: item.id },
          [
            _c(
              "b-card",
              {
                staticStyle: { "background-color": "rgba(242, 242, 242, 0.5)" },
                attrs: { "no-body": "" },
              },
              [
                _c(
                  "b-row",
                  {
                    staticStyle: { "min-height": "100%" },
                    attrs: { "no-gutters": "", "align-v": "stretch" },
                  },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "pl-4",
                        attrs: {
                          cols: item.image ? "8" : "12",
                          "align-self": "center",
                        },
                      },
                      [
                        _c("h3", { staticClass: "mb-4" }, [
                          _vm._v("Your add-ons:"),
                        ]),
                        _c("h2", { staticClass: "mb-0" }, [
                          _c("b", [_vm._v(_vm._s(item.name))]),
                        ]),
                      ]
                    ),
                    item.image
                      ? _c(
                          "b-col",
                          { staticClass: "p-0", attrs: { cols: "4" } },
                          [
                            _c("div", { staticClass: "img-holder" }, [
                              _c("img", {
                                attrs: { src: item.image, alt: item.name },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._l(item.pricing, function (pricing) {
              return _c("w-checkbox", {
                key: pricing.id,
                attrs: {
                  type: "radio",
                  name: pricing.product,
                  id: pricing.id,
                  label: pricing.nickname,
                  pricing: _vm.formatPricing(
                    pricing.type,
                    pricing.price,
                    pricing.recurring
                  ),
                  value: {
                    productid: item.id,
                    pricingid: pricing.id,
                  },
                  bold: "",
                },
                model: {
                  value: _vm.items[i],
                  callback: function ($$v) {
                    _vm.$set(_vm.items, i, $$v)
                  },
                  expression: "items[i]",
                },
              })
            }),
          ],
          2
        )
      }),
      _c(
        "b-button",
        {
          attrs: {
            pill: "",
            block: "",
            variant: "info",
            disabled: _vm.disabled,
          },
          on: { click: _vm.submitPricing },
        },
        [_vm._v("Continue")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }